body {
  padding-left: 5%;
  padding-right: 5%;
  min-width: 650px;
}

@media (min-width: 800px) {
  body {
      padding-left: 10%;
      padding-right: 10%;
  }
}

@media (min-width: 1100px) {
  body {
      padding-left: 20%;
      padding-right: 20%;
  }
}

.doubleText {
  grid-row: 1;
  display: flex;
  flex-direction: column;
}

.option {
  display: grid;
  grid-template-columns: 10fr auto;
  padding-bottom: 1px;
}
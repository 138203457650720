.headerBar {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-auto-flow: column;
  justify-content: center;
}

.logo {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.error {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  height: 70%;
  justify-content: space-around;
  padding-top: 100px;
}

.link {
  grid-row: 3;
}
.page {
  display: inline-block;
  height: 70%;
  justify-content: space-around;
  padding-top: 100px;
}

.text {
  padding: 14px;
  display: flex;
}

.link {
  padding-top: 42px;
}

.score {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr 50px;
  padding-bottom: 60px;
}

.scoreline {
  justify-self: center;
}

.homeScorers {
  grid-column: 1;
}

.awayScorers {
  grid-column: 3;
}
